/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client } from "../client";
import type { TenantsFilterQueryParameters, MultiTenancyStatusResource, NewTenantResource, ResourceCollection, TagTestResult, TenantMissingVariableResource, TenantsMissingVariablesBffResponse, TenantResource, TenantVariableResource, GetTenantsOverviewBffResponse, GetTenantDetailBffResponse, GetTenantsSummariesBffResponse, } from "../resources";
import type { AllArgs, ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type TenantListArgs = {
    projectId?: string;
} & ListArgs;
type TenantAllArgs = {
    projectId?: string;
} & AllArgs;
type ListTenantsArgs = {
    projectId?: string;
    tags?: string;
    clonedFromTenantId?: string;
    name?: string;
} & ListArgs;
export class TenantRepository extends BasicRepository<TenantResource, NewTenantResource, TenantListArgs, TenantAllArgs> {
    constructor(client: Client) {
        super("Tenants", client);
    }
    status(): Promise<MultiTenancyStatusResource> {
        return this.client.get(this.client.getLink("TenantsStatus"));
    }
    tagTest(tenantIds: string[], tags: string[]): Promise<TagTestResult> {
        return this.client.get(this.client.getLink("TenantTagTest"), { tenantIds, tags });
    }
    getVariables(tenant: TenantResource): Promise<TenantVariableResource> {
        return this.client.get(tenant.Links["Variables"]);
    }
    setVariables(tenant: TenantResource, variables: TenantVariableResource): Promise<TenantVariableResource> {
        return this.client.put(tenant.Links["Variables"], variables);
    }
    missingVariables(filterOptions: FilterOptions = {}, includeDetails: boolean = false): Promise<TenantMissingVariableResource[]> {
        const payload = {
            environmentId: filterOptions.environmentId,
            includeDetails: !!includeDetails,
            projectId: filterOptions.projectId,
            tenantId: filterOptions.tenantId,
        };
        return this.client.get(this.client.getLink("TenantsMissingVariables"), payload);
    }
    missingVariablesBff(projectIds: string[], tenantIds: string[], environmentIds: string[]): Promise<TenantsMissingVariablesBffResponse> {
        const payload = {
            environmentIds: environmentIds,
            projectIds: projectIds,
            tenantIds: tenantIds,
        };
        return this.client.post("~/bff/spaces/{spaceId}/tenants/variables-missing", payload);
    }
    list(args?: ListTenantsArgs): Promise<ResourceCollection<TenantResource>> {
        return this.client.get(this.client.getLink("Tenants"), { ...args });
    }
    tenantsOverview(pageNumber: number, pageSize: number, filter: TenantsFilterQueryParameters): Promise<GetTenantsOverviewBffResponse> {
        const args = { pageNumber, pageSize, ...filter };
        return this.client.get("~/bff/spaces/{spaceId}/tenants/overview{?pageNumber,pageSize,filterByName,filterByTags,filterByProject,filterByEnvironment,filterByExcludedTags,filterByExcludedProject,filterByExcludedEnvironment,filterByExcludedName,includeMissingVariablesStatus}", args);
    }
    tenantsCsv(filter: TenantsFilterQueryParameters): void {
        const args = { ...filter };
        const csvUrl = this.client.resolveUrlWithSpaceId("~/bff/spaces/{spaceId}/tenants/csv{?filterByName,filterByTags,filterByProject,filterByEnvironment,filterByExcludedTags,filterByExcludedProject,filterByExcludedEnvironment,filterByExcludedName,includeMissingVariablesStatus}", args);
        window.location.assign(csvUrl);
    }
    tenantDetail(tenantId: string, includeMissingVariables: boolean): Promise<GetTenantDetailBffResponse> {
        return this.client.get("~/bff/spaces/{spaceId}/tenants/detail/{tenantId}{?includeMissingVariablesStatus}", { tenantId, includeMissingVariablesStatus: includeMissingVariables });
    }
    tenantsSummaries(): Promise<GetTenantsSummariesBffResponse> {
        return this.client.get("~/bff/spaces/{spaceId}/tenants/summaries");
    }
    getVariablesByTenantId(tenantId: string): Promise<TenantVariableResource> {
        return this.client.get("~/api/{spaceId}/tenants/{id}/variables", { id: tenantId });
    }
    setVariablesByTenantId(tenantId: string, variables: TenantVariableResource): Promise<TenantVariableResource> {
        return this.client.put("~/api/{spaceId}/tenants/{id}/variables", variables, { id: tenantId });
    }
}
type FilterOptions = {
    tenantId?: string;
    projectId?: string;
    environmentId?: string;
};
