/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client } from "../client";
import type { AuditArchiveResource } from "../resources/auditArchiveResource";
import { BasicRepository } from "./basicRepository";
import type { ListArgs } from "./basicRepository";
type AuditArchiveListArgs = ListArgs;
export class AuditArchiveRepository extends BasicRepository<AuditArchiveResource, AuditArchiveResource, AuditArchiveListArgs> {
    constructor(client: Client) {
        super("ArchivedEventFiles", client);
    }
}
