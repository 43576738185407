/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { chunk, flatten } from "lodash";
import type { Client, GlobalAndSpaceRootLinks } from "../client";
import type { RouteArgs } from "../resolver";
import type { Resource, ResourceWithId } from "../resources/resource";
import type { ResourceCollection } from "../resources/resourceCollection";
export type ListArgs = {
    skip?: number;
    take?: number;
};
export type AllArgs = {
    ids?: string[];
};
export type ResourcesById<TResource> = {
    [id: string]: TResource;
};
// Repositories provide a helpful abstraction around the Octopus Deploy API
export class BasicRepository<TExistingResource extends ResourceWithId, TNewResource extends Resource, // Should never have a `Links` property, which we rely on in `save`
TListArgs extends ListArgs & RouteArgs = ListArgs, TAllArgs extends AllArgs & RouteArgs = AllArgs, TGetArgs extends RouteArgs = {}, TCreateArgs extends RouteArgs = {}, TModifyArgs extends RouteArgs = {}> {
    protected client: Client;
    protected readonly collectionLinkName: GlobalAndSpaceRootLinks;
    constructor(collectionLinkName: GlobalAndSpaceRootLinks, client: Client) {
        this.collectionLinkName = collectionLinkName;
        this.client = client;
    }
    create(resource: TNewResource, args?: TCreateArgs): Promise<TExistingResource> {
        return this.client.create<TNewResource, TExistingResource>(this.client.getLink(this.collectionLinkName), resource, args!);
    }
    modify(resource: TExistingResource, args?: TModifyArgs): Promise<TExistingResource> {
        return this.client.update(resource.Links.Self, resource, args);
    }
    del(resource: TExistingResource): Promise<unknown> {
        return this.client.del(resource.Links.Self);
    }
    list(args?: TListArgs): Promise<ResourceCollection<TExistingResource>> {
        return this.client.get(this.client.getLink(this.collectionLinkName), args);
    }
    get(id: string, args?: TGetArgs): Promise<TExistingResource> {
        const allArgs = this.extend(args || {}, { id });
        return this.client.get(this.client.getLink(this.collectionLinkName), allArgs);
    }
    all(args?: TAllArgs): Promise<TExistingResource[]> {
        if (args !== undefined && args.ids instanceof Array && args.ids.length === 0) {
            return new Promise((res) => {
                res([]);
            });
        }
        // Cloud permits a max URI length of 8KB (roughly 8k chars). Our typical max id length is 50 chars
        // so if we are doing requests by id and have more than 150, split into multiple requests
        const maxIds = 150;
        if (args !== undefined && args.ids instanceof Array && args.ids.length > maxIds) {
            return this.batchRequestsById(args, maxIds);
        }
        const allArgs = this.extend(args || {}, { id: "all" });
        return this.client.get(this.client.getLink(this.collectionLinkName), allArgs);
    }
    allById(args?: any): Promise<ResourcesById<TExistingResource>> {
        return this.all(args).then((result) => result.reduce((acc: any, resource) => {
            acc[resource.Id] = resource;
            return acc;
        }, {}));
    }
    save(resource: TExistingResource | TNewResource): Promise<TExistingResource> {
        if (isNewResource(resource)) {
            return this.create(resource);
        }
        else {
            return this.modify(resource);
        }
        function isTruthy<T>(value: T): boolean {
            return !!value;
        }
        function isNewResource(resource: TExistingResource | TNewResource): resource is TNewResource {
            return !("Id" in resource && isTruthy(resource.Id) && isTruthy(resource.Links));
        }
    }
    protected extend(arg1: any, arg2: any) {
        return { ...arg1, ...arg2 };
    }
    protected batchRequestsById(args: TAllArgs, batchSize: number): Promise<TExistingResource[]> {
        const idArrays = chunk(args!.ids, batchSize);
        const promises: Array<Promise<TExistingResource[]>> = idArrays.map((ids) => {
            const newArgs = { ...(args as any), ids, id: "all" };
            return this.client.get(this.client.getLink(this.collectionLinkName), newArgs);
        });
        return Promise.all(promises).then((result) => flatten(result));
    }
}
