import type { InputJsonSchema } from "@octopusdeploy/step-runtime-inputs";
import type { Client } from "../client";
import type { MigrateStepPackageInputsResponse, StepPackageDetailsResponse } from "../resources/stepPackage";
import type { StepPackageInputs } from "../resources/stepPackageInputs";
export class StepPackageRepository {
    constructor(private readonly client: Client) { }
    async getStepPackage(stepPackageId: string): Promise<StepPackageDetailsResponse> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/${stepPackageId}/latest-version`);
        if (!response) {
            throw new Error(`No step package was found with id ${stepPackageId}.`);
        }
        return response;
    }
    async getStepPackageByIdAndVersion(stepPackageId: string, stepPackageVersion: string): Promise<StepPackageDetailsResponse> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/${stepPackageId}/${stepPackageVersion}`);
        if (!response) {
            throw new Error(`No step package was found with id ${stepPackageId} and version ${stepPackageVersion}.`);
        }
        return response;
    }
    async migrateStepPackageInputs(stepPackageId: string, stepPackageVersion: string, inputs: StepPackageInputs, targetMajorVersion: number) {
        return await this.client.post<MigrateStepPackageInputsResponse>(`/api/steps/${stepPackageId}/${stepPackageVersion}/migrate-inputs`, { inputs, targetMajorVersion });
    }
    async getStepPackageSchema(stepPackage: StepPackageDetailsResponse): Promise<InputJsonSchema> {
        return await this.client.get<InputJsonSchema>(stepPackage.Links.Schema);
    }
}
