/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client } from "../client";
import { BasicRepository } from "../repositories/basicRepository";
import type { MetadataTypeCollection } from "../resources/dynamicFormResources";
import type { SettingsId } from "./SettingsId";
import type { SettingsMetadataItemResource } from "./settingsMetadataResource";
export class SettingsRepository extends BasicRepository<SettingsMetadataItemResource, SettingsMetadataItemResource> {
    constructor(client: Client) {
        super("Configuration", client);
    }
    getById(id: SettingsId): Promise<SettingsMetadataItemResource> {
        return this.client.get<SettingsMetadataItemResource>(this.client.getLink("Configuration"), { id });
    }
    getValues(resource: SettingsMetadataItemResource): Promise<any> {
        return this.client.get<any>(resource.Links["Values"]);
    }
    getMetadata(resource: SettingsMetadataItemResource): Promise<MetadataTypeCollection> {
        return this.client.get<MetadataTypeCollection>(resource.Links["Metadata"]);
    }
    saveValues(metadataResource: SettingsMetadataItemResource, resource: any) {
        return this.client.put(metadataResource.Links["Values"], resource);
    }
}
