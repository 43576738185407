/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Client } from "../client";
import type { ChannelResource, GetReleaseDetailBffResponse, OverviewReleaseBff, ProjectResource } from "../resources";
import type { GetCreateDeploymentDeploymentPreviewsBffResponse } from "../resources/deploymentPreviewResource";
import type { DeploymentResource } from "../resources/deploymentResource";
import type { DeploymentTemplateResource } from "../resources/deploymentTemplateResource";
import type { LatestReleaseResource } from "../resources/latestReleaseResource";
import type { LifecycleProgressionResource } from "../resources/lifecycleProgressionResource";
import type { ReleaseResource } from "../resources/releaseResource";
import type { ResourceCollection } from "../resources/resourceCollection";
import type { VariableSetResource } from "../resources/variableSetResource";
import type { ListArgs } from "./basicRepository";
import { BasicRepository } from "./basicRepository";
type GetDeploymentArgs = ListArgs;
export class ReleasesRepository extends BasicRepository<ReleaseResource, ReleaseResource> {
    constructor(client: Client) {
        super("Releases", client);
    }
    getDeployments(release: ReleaseResource, options?: GetDeploymentArgs): Promise<ResourceCollection<DeploymentResource>> {
        return this.client.get(release.Links["Deployments"], options);
    }
    getUnpaginatedDeployments(release: ReleaseResource, options?: GetDeploymentArgs): Promise<ResourceCollection<DeploymentResource>> {
        return this.client.get(`~/bff/spaces/{spaceId}/releases/{releaseId}/deployments{?take}`, { spaceId: release.SpaceId, releaseId: release.Id, take: options?.take });
    }
    getDeploymentTemplate(release: ReleaseResource | OverviewReleaseBff): Promise<DeploymentTemplateResource> {
        return this.client.get(release.Links["DeploymentTemplate"]) as Promise<DeploymentTemplateResource>;
    }
    getDeploymentPreviewsBff(release: ReleaseResource, environmentIds: string[], tenantIds: string[]): Promise<GetCreateDeploymentDeploymentPreviewsBffResponse> {
        return this.client.post("~/bff/spaces/{spaceId}/releases/{releaseId}/deployments/previews", { EnvironmentIds: environmentIds, TenantIds: tenantIds }, { releaseId: release.Id, includeDisabledSteps: false });
    }
    async progression(release: ReleaseResource): Promise<LifecycleProgressionResource> {
        const updatedReleaseProgression: LifecycleProgressionResource = await this.client.get(release.Links["Progression"]);
        this.client.dispatchEvent({ type: "ReleaseProgressionModified" });
        return updatedReleaseProgression;
    }
    async getViewReleaseDetailsBff(release: ReleaseResource, numberOfDeploymentsPerEnvironment: number): Promise<GetReleaseDetailBffResponse> {
        const args = { numberOfDeploymentsPerEnvironment };
        const updatedReleaseProgression: GetReleaseDetailBffResponse = await this.client.get(`~/bff/spaces/${release.SpaceId}/releases/${release.Id}{?numberOfDeploymentsPerEnvironment}`, args);
        this.client.dispatchEvent({ type: "ReleaseProgressionModified" });
        return updatedReleaseProgression;
    }
    snapshotVariables(release: ReleaseResource): Promise<ReleaseResource> {
        return this.client.post(release.Links["SnapshotVariables"]);
    }
    variables(release: ReleaseResource): Promise<VariableSetResource[]> {
        return this.client.get(release.Links["Variables"]);
    }
    getChannel(release: ReleaseResource): Promise<ChannelResource> {
        return this.client.get(release.Links["Channel"]);
    }
    getLatest(project: ProjectResource): Promise<LatestReleaseResource[]> {
        return this.client.get<LatestReleaseResource[]>("~/bff/spaces/{spaceId}/releases/latest/{projectId}", {
            spaceId: project.SpaceId,
            projectId: project.Id,
        });
    }
}
