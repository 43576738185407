import type { Client } from "../client";
import type { GitHubAppAuthorizationStatus, GitHubAppConnectionResource, GitHubAppConnections, GitHubAppInstallations, GitHubAppRepositories, PagedGitHubAppRepositories } from "../resources";
export interface CreateGitHubAppConnectionCommand {
    InstallationId: string;
    RepositoryIds: string[];
}
export interface ModifyGitHubAppConnectionCommand {
    RepositoryIds: string[];
}
export interface CreateGitHubAppConnectionResponse {
    ConnectionId: string;
}
export class GitHubAppRepository {
    protected client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getAuthorizeAppUrl(redirectUri: string): string {
        return this.client.resolve("~/api/github/user/app/authorize{?redirectUri}", { redirectUri });
    }
    getInstallationUrl(redirectUri: string): string {
        return this.client.resolve("~/api/github/accounts/install-url{?redirectUri}", { redirectUri });
    }
    deleteToken() {
        return this.client.delete("~/api/github/user/app/token", null);
    }
    getInstallations(excludeConnected: boolean) {
        return this.client.get<GitHubAppInstallations>("~/api/{spaceId}/github/installations?excludeConnected={excludeConnected}", { excludeConnected });
    }
    getRepositories(installationId: string, skip: number, take: number) {
        return this.client.get<PagedGitHubAppRepositories>("~/api/{spaceId}/github/installations/{installationId}/repositories{?skip,take}", { installationId, skip, take });
    }
    getRepositoriesForConnection(connectionId: string) {
        return this.client.get<GitHubAppRepositories>("~/api/{spaceId}/github/connections/{connectionId}/repositories", { connectionId });
    }
    getConnection(id: string) {
        return this.client.get<GitHubAppConnectionResource>("~/api/{spaceId}/github/connections/{id}", { id });
    }
    refreshConnection(connectionId: string) {
        return this.client.post("~/api/{spaceId}/github/connections/{connectionId}/refresh", null, { connectionId });
    }
    addConnection(command: CreateGitHubAppConnectionCommand) {
        return this.client.post<CreateGitHubAppConnectionResponse>("~/api/{spaceId}/github/connections", command);
    }
    deleteConnection(id: string) {
        return this.client.delete("~/api/{spaceId}/github/connections/{id}", null, { id });
    }
    modifyConnection(id: string, command: ModifyGitHubAppConnectionCommand) {
        return this.client.put("~/api/{spaceId}/github/connections/{id}", command, { id });
    }
    getConnections(skip: number, take: number): Promise<GitHubAppConnections> {
        const args = { skip, take };
        return this.client.get<GitHubAppConnections>("~/api/{spaceId}/github/connections{?skip}{&take}", args);
    }
    getAuthorizationStatus(): Promise<GitHubAppAuthorizationStatus> {
        return this.client.get<GitHubAppAuthorizationStatus>("~/api/github/user/app/authorization_status");
    }
    getAuthorizationStatusWithUserDetails(): Promise<GitHubAppAuthorizationStatus> {
        return this.client.get<GitHubAppAuthorizationStatus>("~/api/github/user/app/authorization_status?includeUserDetails=true");
    }
    refreshUser() {
        return this.client.post<GitHubAppAuthorizationStatus>("~/api/github/user/app/token/refresh");
    }
    clearUser() {
        return this.client.delete("~/api/github/user/app/token");
    }
}
