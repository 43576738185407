import type { Client } from "../client";
export type DeprecationResponse = {
    Deprecations: DeprecationResource[];
};
export type DeprecationResource = {
    Id: string;
    Title: string;
    Message: string;
    Link: string;
    Usages: number;
    LegacyModeEnabled: boolean;
};
export class DeprecationRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }
    getDeprecations(): Promise<DeprecationResponse> {
        return this.client.get(`~/bff/deprecations`);
    }
}
