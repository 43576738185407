import type { Client } from "../client";
import type { NewSpaceResource, SpaceResource } from "../resources";
import { BasicRepository } from "./basicRepository";
export class SpaceRepository extends BasicRepository<SpaceResource, NewSpaceResource> {
    constructor(client: Client) {
        super("Spaces", client);
    }
    async create(resource: NewSpaceResource, args?: {}): Promise<SpaceResource> {
        const result = await super.create(resource, args);
        this.client.dispatchEvent({ type: "SpaceCreated" });
        return result;
    }
    async modify(resource: SpaceResource, args?: {}): Promise<SpaceResource> {
        const result = await super.modify(resource, args);
        this.client.dispatchEvent({ type: "SpaceModified" });
        return result;
    }
    async del(resource: SpaceResource) {
        await super.del(resource);
        this.client.dispatchEvent({ type: "SpaceDeleted" });
    }
    search(keyword: string) {
        return this.client.get<SpaceSearchResult[]>(this.client.getLink("SpaceSearch"), { id: this.client.spaceId, keyword: keyword });
    }
}
export interface SpaceSearchResult {
    Id: string;
    Name: string;
    Type: string;
}
