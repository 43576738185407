import { cx, css } from "@emotion/css";
import { resetStyles, useOctopusLinkComponent } from "@octopusdeploy/design-system-components";
import { borderRadius, borderWidth, colorScales, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import type { LinkHref } from "@octopusdeploy/portal-routes";
import React from "react";
import type { ListItemElement } from "./List";
interface DropdownListItemProps {
    title: string;
    description?: string;
    href: LinkHref;
    logo: {
        href: string;
        accessibleName: string;
    };
    tabIndex?: number;
    onClick?: React.MouseEventHandler;
}
export const DropdownListItem = React.forwardRef<ListItemElement, DropdownListItemProps>(({ onClick, title, description, href, logo, tabIndex }, ref) => {
    const focusRef = React.useRef<HTMLAnchorElement>(null);
    const Link = useOctopusLinkComponent();
    const hasDescription = !!description;
    const styles = [linkStyles, hasDescription ? linkTwoLineStyles : linkSingleLineStyles];
    React.useImperativeHandle(ref, () => ({
        focus: () => {
            focusRef.current?.focus();
        },
    }));
    return (<li>
            <Link className={cx(styles)} href={href} tabIndex={tabIndex} ref={focusRef} onClick={onClick}>
                <div className={listItemLogoContainerStyles}>
                    <img className={logoStyles} src={logo.href} aria-label={logo.accessibleName}/>
                </div>
                <span className={listItemNameStyles} title={title}>
                    {title}
                </span>
                {hasDescription && <span className={listItemDescriptionStyles}>{description}</span>}
            </Link>
        </li>);
});
DropdownListItem.displayName = "DropdownListItem";
const linkStyles = css({
    ...resetStyles.anchor,
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
    alignItems: "center",
    columnGap: space[12],
    padding: `${space[8]} ${space[12]}`,
    borderRadius: borderRadius.large,
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focus.default,
    },
    ":hover": {
        background: themeTokens.color.menuList.background.hover,
    },
});
const linkSingleLineStyles = css({
    gridTemplateRows: "auto",
    gridTemplateAreas: "'image title'",
});
const linkTwoLineStyles = css({
    gridTemplateRows: "auto auto",
    gridTemplateAreas: "'image title' 'image description'",
});
const listItemLogoContainerStyles = css({
    boxSizing: "border-box",
    gridArea: "image",
    width: "3rem",
    height: "3rem",
    borderRadius: borderRadius.small,
    outline: `${borderWidth["1"]} solid rgba(104, 119, 141, 0.24)`,
    outlineOffset: `-${borderWidth["1"]}`,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    overflow: "clip",
    background: colorScales.white,
});
const logoStyles = css({
    borderRadius: borderRadius.extraSmall,
    objectFit: "scale-down",
    width: "100%",
    height: "100%",
});
const listItemNameStyles = css({
    font: text.menuList.base,
    gridArea: "title",
    color: themeTokens.color.menuList.text.primary,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});
const listItemDescriptionStyles = css({
    font: text.menuList.small,
    gridArea: "description",
    color: themeTokens.color.menuList.text.secondary,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
});
