import type { Client } from "../client";
import type { ProjectResource, GitBranchResource } from "../resources";
import type { GitRef } from "../resources/versionControlledResource";
export class BranchesRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }
    createBranch(project: ProjectResource, newBranchName: String, baseGitRef: GitRef): Promise<GitBranchResource> {
        return this.client.post(`/api/${project.SpaceId}/projects/${project.Id}/git/branches/v2`, { NewBranchName: newBranchName, BaseGitRef: baseGitRef });
    }
}
