import type { Client } from "../client";
import type { GetProjectGroupsLookupAcrossAllSpacesBffResponse, ProjectGroupLookup, ProjectGroupResource } from "../resources";
import { BasicRepository } from "./basicRepository";
export class ProjectGroupRepository extends BasicRepository<ProjectGroupResource, ProjectGroupResource> {
    constructor(client: Client) {
        super("ProjectGroups", client);
    }
    async getProjectGroupsLookupAcrossAllSpaces(args?: {
        filterBySpace?: string;
        filterByExcludedSpace?: string;
    }): Promise<ProjectGroupLookup[]> {
        const response = await this.client.get<GetProjectGroupsLookupAcrossAllSpacesBffResponse>(`~/bff/projectgroups/lookup{?filterBySpace,filterByExcludedSpace}`, args);
        return response.ProjectGroupLookup;
    }
}
