/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Client } from "../client";
import type { UserOnboardingResource } from "../resources/userOnboardingResource";
import { BasicRepository } from "./basicRepository";
export type GetOrCreateUserOnboardingResponse = {
    UserOnboardingResource: UserOnboardingResource;
};
export type ModifyUserOnboardingCommand = {
    WelcomeDialogDismissedDate: Date | null;
};
export type ModifyUserOnboardingResponse = {
    UserOnboardingResource: UserOnboardingResource;
};
export class UserOnboardingRepository extends BasicRepository<UserOnboardingResource, UserOnboardingResource> {
    constructor(client: Client) {
        super("UserOnboarding", client);
    }
    getForCurrentUser() {
        return this.client.put<GetOrCreateUserOnboardingResponse>(`~/bff/onboarding`);
    }
    modifyForCurrentUser(resource: UserOnboardingResource) {
        return this.client.put(`~/bff/onboarding/${resource.Id}`, resource);
    }
}
