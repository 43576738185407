import { css } from "@emotion/css";
import { Button, PlusIcon } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { SpaceResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import { DropdownSwitcherListLayout } from "../../Dropdown/DropdownSwitcherListLayout";
import { NoSearchResults } from "../../Search/NoSearchResults";
import { SpaceList } from "./SpaceList";
export interface SpaceSearchPanelProps {
    spaces: SpaceResource[];
    canCreateNewSpace: boolean;
    isWithinLicenseLimit: boolean;
    onNewSpaceRequested: () => void;
    onUnlockMoreSpacesRequested: () => void;
    isLoading: boolean;
    errorAlert?: React.ReactNode;
    searchValue: string;
    onSearchValueChanged: (value: string) => void;
    onSpaceSelected?: () => void;
}
export function SpaceSearchPanel({ spaces, canCreateNewSpace, isWithinLicenseLimit, onNewSpaceRequested, onUnlockMoreSpacesRequested, isLoading, errorAlert, searchValue, onSearchValueChanged, onSpaceSelected }: SpaceSearchPanelProps) {
    const filteredSpaces = React.useMemo(() => filterSpaces(spaces, searchValue), [spaces, searchValue]);
    const noResults = searchValue.length > 0 && filteredSpaces.length === 0;
    return (<DropdownSwitcherListLayout searchPlaceholder="Quick search all spaces" searchValue={searchValue} onSearchValueChanged={onSearchValueChanged} searchAccessibleName="Search spaces" actionButton={canCreateNewSpace && <Button label="Add space" importance="tertiary" leftIcon={<PlusIcon />} onClick={onNewSpaceRequested}/>} renderList={(listRef) => (<>
                    {noResults ? <NoSearchResults searchInput={searchValue}/> : <SpaceList spaces={filteredSpaces} ref={listRef} onSpaceSelected={onSpaceSelected}/>}
                    {!isWithinLicenseLimit && (<div className={unlockMoreSpacesContainerStyles}>
                            <Button label="Unlock more spaces" importance="primary" onClick={onUnlockMoreSpacesRequested}/>
                        </div>)}
                </>)} isLoading={isLoading} errorAlert={errorAlert}/>);
}
function filterSpaces(spaces: SpaceResource[], filter: string) {
    const query = filter.toLowerCase().trim();
    if (!query)
        return spaces;
    return spaces.filter(({ Name, Description }: SpaceResource) => Name.toLowerCase().includes(query) || Description?.toLowerCase().includes(query));
}
const unlockMoreSpacesContainerStyles = css({
    display: "flex",
    justifyContent: "center",
    padding: space[16],
});
