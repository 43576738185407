import type { Client } from "../client";
import { ConfigurationRepository } from "../repositories/configurationRepository";
import type { AuditStreamConfigurationResource } from "../resources/auditStreamConfigurationResource";
import type { AuditStreamLicenseCheckResource } from "../resources/auditStreamLicenseCheckResource";
export class AuditStreamConfigurationRepository extends ConfigurationRepository<AuditStreamConfigurationResource> {
    constructor(client: Client) {
        super("AuditStreamConfiguration", client);
    }
    getAuditStreamLicenseCheck(): Promise<AuditStreamLicenseCheckResource> {
        return this.client.get("~/bff/audit-stream/license-check");
    }
    async requestTrial() {
        return await this.client.post("~/bff/audit-stream/request-audit-stream-trial");
    }
}
