import type { Client } from "../client";
export type InstallationHistoryResource = {
    Version: string;
    InstalledAt: string;
};
export class InstallationHistoryRepository {
    constructor(private readonly client: Client) {
        this.client = client;
    }
    getInstallationHistory(): Promise<InstallationHistoryResource[]> {
        return this.client.get<InstallationHistoryResource[]>(`~/bff/installationhistory`);
    }
}
