/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Client } from "../client";
import type { ResourceWithId } from "../resources/resource";
export class LogoRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    /**
     * **Important:** Usage of saveLogo should be done before saving the resource model.
     * This will allow the updated logo url to be returned as part of the save model response.
     * @param resource The resource that this logo is for.
     * @param file The file for the logo.
     * @param reset Whether the logo should be reset.
     */
    saveLogo<T extends ResourceWithId>(resource: T, file: File | undefined, reset: boolean) {
        if (file) {
            return this.uploadLogo(resource, file);
        }
        else if (reset) {
            return this.uploadLogo(resource, null!);
        }
    }
    saveIcon<T extends ResourceWithId>(resource: T, iconId: string, iconColor: string) {
        const body = {
            IconId: iconId,
            IconColor: iconColor,
        };
        const url = `${resource.Links["Logo"].split("?")[0]}/icon`;
        return this.client.post(url, body);
    }
    private uploadLogo<T extends ResourceWithId>(resource: T, logo: File) {
        const fd = new FormData();
        fd.append("fileToUpload", logo);
        return this.client.post(resource.Links["Logo"], fd);
    }
}
