import type { Client } from "../client";
import type { OctopusServerNodeDetailsResource, OctopusServerNodeSummaryResource, ResourceCollection } from "../resources";
import type { OctopusServerClusterSummaryResource } from "../resources/octopusServerClusterSummaryResource";
import type { OctopusServerNodeResource } from "../resources/octopusServerNodeResource";
import { BasicRepository } from "./basicRepository";
export class OctopusServerNodeRepository extends BasicRepository<OctopusServerNodeResource, OctopusServerNodeResource> {
    constructor(client: Client) {
        super("OctopusServerNodes", client);
    }
    //technically deprecated, as its not called from the UI.
    //introduced in 2019.1.0, the code that called it got changed soon after
    details(node: OctopusServerNodeResource): Promise<ResourceCollection<OctopusServerNodeDetailsResource>> {
        return this.client.get(node.Links["Details"]);
    }
    summary(): Promise<OctopusServerClusterSummaryResource> {
        return this.client.get(this.client.getLink("OctopusServerClusterSummary"));
    }
    del(resource: OctopusServerNodeSummaryResource) {
        return this.client.del(resource.Links.Node);
    }
}
