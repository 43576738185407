import type { Client } from "../client";
import type { DeploymentTargetResource, EnvironmentResource, EnvironmentsSummaryResource, NewEnvironmentResource, ResourceCollection, VariablesScopedToDocumentResource } from "../resources";
import type { EnvironmentSettingsMetadata } from "../resources/extensionSettingsValues";
import { BasicRepository } from "./basicRepository";
export class EnvironmentRepository extends BasicRepository<EnvironmentResource, NewEnvironmentResource> {
    constructor(client: Client) {
        super("Environments", client);
    }
    async create(resource: NewEnvironmentResource, args?: {}): Promise<EnvironmentResource> {
        const environment = await super.create(resource, args);
        this.client.dispatchEvent({ type: "EnvironmentCreated" });
        return environment;
    }
    sort(order: string[]) {
        return this.client.put(this.client.getLink("EnvironmentSortOrder"), order);
    }
    variablesScopedOnlyToThisEnvironment(environment: EnvironmentResource): Promise<VariablesScopedToDocumentResource> {
        return this.client.get<VariablesScopedToDocumentResource>(environment.Links["SinglyScopedVariableDetails"]);
    }
    machines(environment: EnvironmentResource, args?: Partial<EnvironmentMachinesArgs>): Promise<ResourceCollection<DeploymentTargetResource>> {
        return this.client.get<ResourceCollection<DeploymentTargetResource>>(environment.Links["Machines"], args);
    }
    summary(args?: Partial<EnvironmentSummaryArgs>) {
        return this.client.get<EnvironmentsSummaryResource>(this.client.getLink("EnvironmentsSummary"), args);
    }
    getMetadata(environment: EnvironmentResource): Promise<EnvironmentSettingsMetadata[]> {
        return this.client.get(environment.Links["Metadata"], {});
    }
}
export type EnvironmentMachinesArgs = {
    skip: number;
    take: number;
    partialName: string | undefined;
    roles: string | null;
    isDisabled: boolean;
    healthStatuses: string | null;
    tenantIds: string | null;
    tenantTags: string | null;
    shellNames: string | null;
    deploymentTargetTypes: string | null;
};
export type EnvironmentSummaryArgs = {
    ids: string;
    partialName: string;
    machinePartialName: string;
    roles: string;
    isDisabled: boolean;
    healthStatuses: string;
    tenantIds: string;
    tenantTags: string;
    hideEmptyEnvironments: boolean;
    shellNames: string;
    deploymentTargetTypes: string;
};
