import type { Client } from "../client";
import type { StepPackageDetailsResponse } from "../resources/stepPackage";
import type { StepPackageDeploymentTargetType } from "../resources/stepPackageDeploymentTargetType";
export class StepPackageDeploymentTargetRepository {
    constructor(private readonly client: Client) { }
    async getStepPackageDeploymentTargetTypes(): Promise<StepPackageDeploymentTargetType[]> {
        const deploymentTargetsLink = this.client.getLink("StepPackageDeploymentTargetTypes");
        return await this.client.get<StepPackageDeploymentTargetType[]>(deploymentTargetsLink);
    }
    async getLatestStepPackageByDeploymentTargetType(deploymentTargetTypeId: string): Promise<StepPackageDetailsResponse> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/deploymenttargets/${deploymentTargetTypeId}/latest-version`);
        if (!response) {
            throw new Error(`No deployment target was found with id ${deploymentTargetTypeId}.`);
        }
        return response;
    }
    async getStepPackageByDeploymentTargetType(deploymentTargetTypeId: string, version: string): Promise<StepPackageDetailsResponse> {
        const response = await this.client.get<StepPackageDetailsResponse>(`/api/steps/deploymenttargets/${deploymentTargetTypeId}/${version}`);
        if (!response) {
            throw new Error(`No deployment target was found with id ${deploymentTargetTypeId} and version ${version}.`);
        }
        return response;
    }
}
